import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import DJService from '../components/DJService';
import Layout from '../components/Layout';
import acousticDuo from '../assets/images/acoustic-duo/acoustic-duo-profile.jpg';

const SongsTemplate = ({ data }) => (
  <Layout>
    <Helmet>
      <title>
        Acoustic Duo Bristol, Acoustic Duo Wedding Hire Bath - Audio Vultures Acoustic Duo
      </title>
      <meta
        name="description"
        content="View the song repertoire of Bristol party band Audio Vultures. One of the premier live bands for hire in Bath and Bristol, we have a great setlist covering all genres!"
      />
    </Helmet>
    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Audio Vultures Acoustic Duo</h1>
          </header>

          <div className="row">
            <div className="6u 12u$(small)">
              <p>
                Consisting of frontman Danny and lead guitarist Steve performing relaxed renditions
                of a range of classics spanning all eras, the Audio Vultures Acoustic Duo is perfect
                for smaller venues, more low-key occasions and afternoon wedding reception music to
                entertain your guests.
              </p>
              <p>
                Available to perform throughout Bristol, Bath and further afield, the Audio Vultures
                Acoustic Duo are also the perfect accompaniment to your wedding afternoon, setting
                the relaxed vibe before the evening party! See our repertoire of acoustic hits and
                classics below and <a href="#contact">contact us</a> today for a no obligation
                quote!
              </p>
              <p>
                We can be booked for up to <b>an hour set</b>, or <b>two 30 minute sets.</b>
              </p>
              <iframe
                width="100%"
                height="330"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                title="Audio Vultures Acoustic Duo"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1050371758&color=%23ff9900&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>{' '}
            </div>
            <div className="6u 12u$(small)">
              <span className="image fit thumb">
                <img src={acousticDuo} alt={'Audio Vultures Acoustic Duo'} />
              </span>
            </div>
          </div>
          <hr className="major" />

          <div className="row">
            <div className="12u 12u$(small)">
              <div className="table-wrapper">
                <table>
                  <thead>
                    <tr>
                      <th>Song</th>
                      <th>Artist</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.allGoogleSheetAcousticDuoRow.edges.map((d) => (
                      <tr key={d.node.id}>
                        <td> {d.node.song}</td>
                        <td> {d.node.artist}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>

      <DJService />
    </div>
  </Layout>
);

export const query = graphql`
  query acousticSongQuery {
    allGoogleSheetAcousticDuoRow(sort: { fields: song, order: ASC }) {
      edges {
        node {
          id
          artist
          song
        }
      }
    }
  }
`;

export default SongsTemplate;
