import React from 'react';
import Link from 'gatsby-link';

const DJService = () => (
  <section id="banner" className="major">
    <div className="inner">
      <header className="major">
        <h1>DJ Service</h1>
      </header>
      <div className="content">
        <p>
          Do you also require a DJ for your event? Audio Vultures can also provide a complete DJ
          service for both before and after our full band set. We have a comprehensive all digital
          song catalogue that spans all decades right up to today’s chart.
        </p>
        <p>
          <Link to="#contact"> Simply enquire today</Link> for more information and we will be happy
          to discuss this further!
        </p>
      </div>
    </div>
  </section>
);

export default DJService;
